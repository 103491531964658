/* Global CSS */
html,body{margin:0;padding:0;}
body{font-family:'Open Sans',sans-serif;font-weight:400;margin:0 auto;color:#6f6f6f;background-color: #e0e0e0;font-size: 14px;}
a, a:hover{-webkit-transition:all 0.5s ease-in-out;-moz-transition:all 0.5s ease-in-out;-ms-transition:all 0.5s ease-in-out;-o-transition:all 0.5s ease-in-out;transition:all 0.5s ease-in-out;outline:none;}
a:hover, a:focus{text-decoration:none;outline:none;-webkit-transition:all 0.5s ease-in-out;-moz-transition:all 0.5s ease-in-out;-ms-transition:all 0.5s ease-in-out;-o-transition:all 0.5s ease-in-out;transition:all 0.5s ease-in-out;}
h1, h2, h3, h4, h5, h6{margin-top:0;}
p:last-child{margin-bottom:0 !important;}
b, strong{font-weight:bold;}
img{max-width:100%;height:auto;}
::-webkit-input-placeholder{color:#737373 !important;opacity:1;}
:-moz-placeholder{color:#737373 !important;opacity:1;}
::-moz-placeholder{color:#737373 !important;opacity:1;}
:-ms-input-placeholder{color:#737373 !important;opacity:1;}
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="password"],
input[type="number"],
input[type="search"],
select,
textarea{display: block;width: 100%;height: 34px;padding: 6px 12px;font-size: 14px;line-height: 1.42857143;color: #555;background-color: #fff;background-image: none;border: 1px solid #ccc;border-radius: 0;box-shadow: none;border-color: #d2d6de;}
textarea{max-width:100%;height:150px;}
dl, ol, ul {margin-top: 0;margin-bottom: 0;padding: 0;list-style: none;}
.bootstrap-select>.dropdown-toggle{width: 100%;height: 34px;padding: 6px 12px;font-size: 14px;line-height: 1.42857143;color: #555;background-color: #fff;background-image: none;border: 1px solid #ccc;border-radius: 0;box-shadow: none;border-color: #d2d6de;}
.bootstrap-select .dropdown-item{padding: 6px 12px;
font-size: 14px;
line-height: 1.42857143;
color: #555;}
.label-style{margin-bottom: 5px;font-size: 15px;line-height: 20px;color: #333;font-weight: 700;}
.label-style span:after {content: "*";color: #f00;font-size: 10px;position: relative;top: -3px;}
.bootstrap-select > .dropdown-menu{padding: 0;}
.bootstrap-select  .dropdown-item.active, .bootstrap-select  .dropdown-item:active{background-color: #3c8dbc;color: #fff;}
.btn{padding: 4px 15px 7px;min-width: 80px;font-size: 15px;border-radius: 0;}
.btn-primary {background-color: #3c8dbc;border-color: #367fa9;}
.btn-primary:hover, .btn-primary:active, .btn-primary.hover {background-color: #367fa9;border-color: #367fa9;}

.top-title{font-size: 25px;line-height: 35px;color: #444;}

.order-13 {-ms-flex-order: 13;order: 13;}
.order-14 {-ms-flex-order: 14;order: 14;}
.order-15 {-ms-flex-order: 15;order: 15;}
.order-16 {-ms-flex-order: 16;order: 16;}
.order-17 {-ms-flex-order: 17;order: 17;}
.order-18 {-ms-flex-order: 18;order: 18;}
.order-19 {-ms-flex-order: 19;order: 19;}
.order-20 {-ms-flex-order: 20;order: 20;}

.loader {position: fixed;left: 0px;top: 0;width: 100%;height: 100%;z-index: 9999;background: #ffffff;background-size: 100px;opacity: 1;}

.dot {height: 8px;width: 8px;background-color: #3c8dbc;border-radius: 50%;position: absolute;top: 50%;left: calc(50% - 4px);transform-origin: 5px 30px;animation: rotate 1.5s infinite;}

@keyframes rotate {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(360deg);
  }
}
.d2 {animation-delay: 0.1s;opacity: .9;}
.d3 {animation-delay: 0.2s;opacity: .8;}
.d4 {animation-delay: 0.3s;opacity: .7;}
.d5 {animation-delay: 0.4s;opacity: .6;}
.table thead th {vertical-align: bottom;border-bottom: 2px solid #dee2e6;background: #3c8dbc;color: #fff;}

/* Header Style */
header{background: #3c8dbc;color: #fff;padding: 15px 15px;}
header h3{font-size: 20px;margin-bottom: 0;}
/*Main body start*/
.main-body{padding: 40px 0;}
/* Box Style */
.box {position: relative;border-radius: 3px;background: #ffffff;border-top: 3px solid #d2d6de;margin-bottom: 20px;width: 100%;box-shadow: 0 1px 1px rgba(0,0,0,0.1);}
.box-header.with-border {border-bottom: 1px solid #f4f4f4;}
.box-footer.with-border {border-top: 1px solid #f4f4f4;}
.box-header, .box-footer {color: #444;display: block;padding: 10px 15px;position: relative;}
.box-header .box-title {display: inline-block;font-size: 18px;margin: 0;line-height: 25px;}
.box-body {border-top-left-radius: 0;border-top-right-radius: 0;border-bottom-right-radius: 3px;border-bottom-left-radius: 3px;padding:15px;}
.box.box-primary {border-top-color: #3c8dbc;}
.box-subtitle{font-size: 18px;margin-bottom: 5px;color: #444;font-weight: 500;}
.box-text{font-size: 13px;}
.note{font-size: 13px;color: #f00;}
/* Footer Style */
.main-footer {background: #fff;padding: 15px;color: #444;border-top: 1px solid #d2d6de;}
.main-footer h6{margin: 0;font-size: 14px;line-height: 24px;}
.main-footer h6 span{color: #3c8dbc;font-weight: 600;}
.main-footer .company-link{color: #3c8dbc;font-weight: 600;float: right;font-size: 14px;}
.main-footer .company-link:hover, .main-footer .company-link:focus{outline: none;text-decoration: none;color: #72afd2;}

/* lable as button style */
.btn-lable{border-radius: 0;cursor: pointer;font-size: 15px;margin-bottom: 15px;transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;box-shadow: 0px 0px 2px 0px #ddd;background: #f9f9f9;}
.btn-lable:hover{background-color: #3c8dbc;color: #fff;border-color: #3c8dbc;}

.box-body .magic-checkbox+label, .box-body .magic-radio+label{line-height: 18px;font-size: 13px;}
.box-body .table td .list-unstyled li{margin-bottom: 15px;}
.box-body .table td .list-unstyled li:last-child{margin-bottom: 0;}
.col-20 {position: relative;width: 100%;padding-right: 15px;padding-left: 15px;-ms-flex: 0 0 20%;flex: 0 0 20%;max-width: 20%;}

/******** Login CSS ********/
.login-logo{font-size: 35px;text-align: center;margin-bottom: 25px;font-weight: 300;color: #333;}
.login-box{width: 380px;margin: 7% auto;display: block;}
.login-box-body{background: #fff;padding: 20px;border-top: 0;color: #666;}
.login-box-msg {margin: 0;text-align: center;padding: 0 20px 20px 20px;font-size: 13px;}
.has-feedback{position: relative;}
.has-feedback .form-control {padding-right: 42.5px;}
.form-control-feedback {position: absolute;top: 9px;right: 12px;z-index: 2;display: block;text-align: center;pointer-events: none;font-size: 15px;}
.b-right:after {content: "";position: absolute;top: 50%;right: 0px;background: #ddd;height: 100px;transform: translateY(-50%);-ms-transform: translateY(-50%);-o-transform: translateY(-50%);-moz-transform: translateY(-50%);-webkit-transform: translateY(-50%);width: 2px;}

/* Responsive CSS */
@media (max-width: 991px){
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto, .col-20{padding-right: 10px;padding-left: 10px;}
  .label-style{min-height: 40px;}
}
@media (max-width: 768px){
  .label-style{font-size: 14px;}
}
@media (max-width: 576px){
  header h3{text-align: center;margin-bottom: 15px;}
  header h3.xs-mb0{margin-bottom: 0;}
  .box-footer .btn{margin: 5px 0;}
  .cw-100{-ms-flex-preferred-size: unset;flex-basis: auto;-ms-flex-positive: unset;flex-grow: unset;max-width: 100%;}
  .label-style{font-size: 15px;min-height: 100%;}
  .login-box{width: 290px;margin: 20px auto 0;display: block;}
  .main-footer{padding: 0;}
}
@media (max-width: 362px){
  .main-footer h6, .main-footer .company-link{font-size: 12px}
  .main-footer .company-link{padding: 3px 0;}
}
/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}
/* 2688x1242px at 458ppi iPhone XS Max*/
@media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 3) { 
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

/* 2436x1125px at 458ppi iPhone 11 Pro, iPhone X and iPhone Xs*/
@media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

/* 1792x828px at 326ppi iPhone 11, iPhone XR*/
@media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 2) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}