.DayPicker {
  display: inline-block;
  font-size: 1rem;
  width: 85%;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  border-radius: 50px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

.react-confirm-alert-body h1 {
  font-size: 25px;
}

.left-align {
  float: left;
}

.custom-list {
  display: flex;
  justify-content: space-between;
}
